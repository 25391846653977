define('ember-cpm/macros/all-equal', ['exports', 'ember-cpm/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _utils.resolveKeysUnsafe)(function (firstVal) {
    for (var i = 0; i < (arguments.length <= 1 ? 0 : arguments.length - 1); i += 1) {
      if ((arguments.length <= i + 1 ? undefined : arguments[i + 1]) !== firstVal) {
        return false;
      }
    }
    return true;
  });
});