define('ember-cli-clipboard/components/copy-button', ['exports', 'ember-cli-clipboard/templates/components/copy-button'], function (exports, _copyButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var run = Ember.run;
  var set = Ember.set;
  var get = Ember.get;
  exports.default = Component.extend({
    layout: _copyButton.default,
    tagName: 'button',
    classNames: ['copy-btn'],
    attributeBindings: ['clipboardText:data-clipboard-text', 'clipboardTarget:data-clipboard-target', 'clipboardAction:data-clipboard-action', 'buttonType:type', 'disabled', 'aria-label', 'title'],

    /**
     * @property {Array} clipboardEvents - events supported by clipboard.js
     */
    clipboardEvents: ['success', 'error'],

    /**
     * @property {String} buttonType - type attribute for button element
     */
    buttonType: 'button',

    /**
     * @property {Boolean} disabled - disabled state for button element
     */
    disabled: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var clipboard = new window.Clipboard('#' + this.get('elementId'));
      set(this, 'clipboard', clipboard);

      get(this, 'clipboardEvents').forEach(function (action) {
        clipboard.on(action, run.bind(_this, function (e) {
          try {
            if (!_this.get('disabled')) {
              _this.sendAction(action, e);
            }
          } catch (error) {
            Ember.Logger.debug(error.message);
          }
        }));
      });
    },
    willDestroyElement: function willDestroyElement() {
      get(this, 'clipboard').destroy();
    }
  });
});