define('ember-cpm/macros/if-null', ['exports', 'ember-macro-helpers/computed'], function (exports, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var _arguments = arguments;

    return _computed.default.apply(undefined, Array.prototype.slice.call(arguments).concat([function (value, fallback) {
      return value != null ? value : fallback || _arguments[1];
    }]));
  };
});