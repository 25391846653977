define('ember-cli-poll/services/poll', ['exports', 'ember'], function (exports, _ember) {

  var Poll = _ember['default'].Service.extend({
    storage: _ember['default'].inject.service('store'),
    setup: function setup(opts) {
      var self = this;
      self.set('polls', self.get('polls') || {});
      var polls = self.get('polls');
      var params = opts.params || {};
      params.poll_at = Date.now();
      polls[opts.name] = {
        resource_name: opts.resource_name,
        url: opts.url,
        params: params
      };
    },
    start: function start(opts) {
      var idle_timeout = opts.idle_timeout || 10000;
      var interval = opts.interval || 2000;
      var self = this;
      if (typeof _ember['default'].$.idle !== "function") {
        self.setIdleListener();
      }

      _ember['default'].$(document).idle({
        onIdle: function onIdle() {
          self.set('pause', true);
        },
        onActive: function onActive() {
          self.set('pause', false);
        },
        idle: idle_timeout
      });

      setInterval(function () {
        if (!self.get('pause')) {
          self.run();
        }
      }, interval);
    },
    run: function run() {
      var self = this;
      var polls = this.get('polls');
      if (polls) {
        var store = self.get('storage');
        if (Object.keys(polls).length) {
          Object.keys(polls).forEach(function (name) {
            var url = polls[name].url;
            var params = polls[name].params;
            url += '?' + _ember['default'].$.param(params);

            _ember['default'].$.getJSON(url, function (data, status, response) {
              if (response.status === 200) {
                store.pushPayload(polls[name].resource_name, data);
                params.poll_at = Date.now();
              }
            });
          });
        }
      }
    },
    removePoll: function removePoll(name) {
      var polls = this.get('polls');
      delete polls[name];
    },
    setIdleListener: function setIdleListener() {
      _ember['default'].$.fn.idle = function (options) {

        var defaults = {
          idle: 60000, //idle time in ms
          events: 'mousemove keypress mousedown touchstart', //events that will trigger the idle resetter
          onIdle: function onIdle() {}, //callback function to be executed after idle time
          onActive: function onActive() {}, //callback function to be executed after back from idleness
          onHide: function onHide() {}, //callback function to be executed when window is hidden
          onShow: function onShow() {}, //callback function to be executed when window is visible
          keepTracking: false //if you want to keep tracking user even after the first time, set this to true
        },
            idle = false,
            visible = true,
            settings = _ember['default'].$.extend({}, defaults, options),
            resetTimeout,
            timeout;

        resetTimeout = function (id, settings) {
          if (idle) {
            settings.onActive.call();
            idle = false;
          }
          (settings.keepTracking ? clearInterval : clearTimeout)(id);

          return timeout(settings);
        };

        timeout = function (settings) {
          var timer = settings.keepTracking ? setInterval : setTimeout,
              id;

          id = timer(function () {
            idle = true;
            settings.onIdle.call();
          }, settings.idle);
          return id;
        };

        return this.each(function () {
          var id = timeout(settings);
          _ember['default'].$(this).on(settings.events, function (e) {
            id = resetTimeout(id, settings);
          });
          if (options.onShow || options.onHide) {
            _ember['default'].$(document).on("visibilitychange webkitvisibilitychange mozvisibilitychange msvisibilitychange", function () {
              if (document.hidden || document.webkitHidden || document.mozHidden || document.msHidden) {
                if (visible) {
                  visible = false;
                  settings.onHide.call();
                }
              } else {
                if (!visible) {
                  visible = true;
                  settings.onShow.call();
                }
              }
            });
          }
        });
      };
    }
  });

  exports['default'] = Poll;
});