define('ember-cpm/macros/html-escape', ['exports', 'ember-cpm/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EmberHandlebars = Ember.Handlebars;
  var EmberString = Ember.String;

  /**
    Returns an Handlebars.SafeString with escaped text.
  
    Example
  
    ```javascript
    var obj = Ember.Object.extend({
      escaped: htmlEscape('value')
    }).create({value: '<em>Hi</em>'});
  
    obj.get('escaped'); // '&lt;em&gt;Hi&lt;/em&gt;'
    ```
  
    @method htmlScape
    @for macros
    @param {String} Dependent key with the string to scape.
    @return {Ember.Handlebars.SafeString} The escaped string.
  */
  exports.default = (0, _utils.resolveKeys)(function (value) {
    if (value == null) {
      return value;
    }

    var escapedExpression = EmberHandlebars.Utils.escapeExpression(value);
    return new EmberString.htmlSafe(escapedExpression);
  });
});