define('phoenix/services/phoenix-socket', ['exports', 'ember', 'phoenix'], function (exports, _ember, _phoenix) {
  var _slice = Array.prototype.slice;
  var assert = _ember['default'].assert;
  var Service = _ember['default'].Service;
  var Evented = _ember['default'].Evented;
  exports['default'] = Service.extend(Evented, {
    socket: null,
    isHealthy: false,

    connect: function connect(url, options) {
      var _this = this,
          _arguments = arguments;

      var socket = new _phoenix.Socket(url, options);
      socket.onOpen(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        _this.set('isHealthy', true);
        _this.trigger.apply(_this, ['open'].concat(_slice.call(_arguments)));
      });
      socket.onClose(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        _this.set('isHealthy', false);
        _this.trigger.apply(_this, ['close'].concat(_slice.call(_arguments)));
      });
      socket.onError(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        _this.set('isHealthy', false);
        _this.trigger.apply(_this, ['error'].concat(_slice.call(_arguments)));
      });
      this.set('socket', socket);
      return socket.connect();
    },

    joinChannel: function joinChannel(name, params) {
      var socket = this.get('socket');
      assert('must connect to a socket first', socket);

      var channel = socket.channel(name, params);
      channel.join();
      return channel;
    }

  });
});