define('paper-data-table/components/paper-data-table', ['exports', 'ember', 'paper-data-table/templates/components/paper-data-table'], function (exports, _ember, _paperDataTableTemplatesComponentsPaperDataTable) {
	var Component = _ember['default'].Component;
	var computed = _ember['default'].computed;
	exports['default'] = Component.extend({
		layout: _paperDataTableTemplatesComponentsPaperDataTable['default'],
		tagName: 'md-table-container',
		classNameBindings: ['noPadding:no-padding-table'],
		bodyComponent: 'paper-data-table-body',
		bodyRowComponent: 'paper-data-table-row',
		headComponent: 'paper-data-table-head',
		rowWidth: 0,
		sortProp: '',
		sortDir: 'asc',
		selectable: false,

		sortDesc: computed('sortProp', 'sortDir', function () {
			var sortDesc = this.getProperties('sortProp', 'sortDir');
			return sortDesc.sortProp + ':' + sortDesc.sortDir;
		}).readOnly(),

		actions: {
			sortChanged: function sortChanged(sortProp, sortDir) {
				this.setProperties({ sortProp: sortProp, sortDir: sortDir });
			}
		}
	});
});