define('paper-data-table/components/paper-data-table-pagination', ['exports', 'ember', 'paper-data-table/templates/components/paper-data-table-pagination'], function (exports, _ember, _paperDataTableTemplatesComponentsPaperDataTablePagination) {
	var Component = _ember['default'].Component;
	var computed = _ember['default'].computed;
	exports['default'] = Component.extend({
		layout: _paperDataTableTemplatesComponentsPaperDataTablePagination['default'],
		tagName: 'md-table-pagination',
		classNames: ['md-table-pagination'],
		startOffset: computed('page', 'limit', function () {
			return Math.max((this.get('page') - 1) * this.get('limit') + 1, 1); // 1-based index
		}),
		endOffset: computed('startOffset', 'limit', function () {
			return this.get('startOffset') + this.get('limit');
		})
	});
});