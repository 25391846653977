define('ember-cpm/macros/first-present', ['exports', 'ember-cpm/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _utils.resolveKeys)(function () {
    for (var _len = arguments.length, values = Array(_len), _key = 0; _key < _len; _key++) {
      values[_key] = arguments[_key];
    }

    for (var i in values) {
      var value = values[i];
      if (Ember.isPresent(value)) {
        return value;
      }
    }
  });
});