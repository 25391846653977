define('paper-data-table/components/paper-data-table-row', ['exports', 'ember', 'paper-data-table/templates/components/paper-data-table-row'], function (exports, _ember, _paperDataTableTemplatesComponentsPaperDataTableRow) {
	var Component = _ember['default'].Component;
	var htmlSafe = _ember['default'].String.htmlSafe;
	var computed = _ember['default'].computed;
	exports['default'] = Component.extend({
		layout: _paperDataTableTemplatesComponentsPaperDataTableRow['default'],
		tagName: 'tr',
		classNames: ['md-row'],
		showEdit: false,
		attributeBindings: ['style'],
		style: computed('edit', 'onClick', function () {
			if (this.get('onClick') || this.get('edit')) {
				return htmlSafe('cursor: pointer;');
			}
			return htmlSafe('');
		}),

		click: function click() {
			this.sendAction('onClick');
			if (this.get('edit')) {
				this.set('showEdit', true);
			}
		},

		actions: {
			close: function close() {
				this.sendAction('onClose', this);
			},
			toggleEdit: function toggleEdit() {
				this.toggleProperty('showEdit');
			}
		}
	});
});