define('paper-data-table/components/paper-data-table-dialog-inner', ['exports', 'ember', 'paper-data-table/templates/components/paper-data-table-dialog-inner'], function (exports, _ember, _paperDataTableTemplatesComponentsPaperDataTableDialogInner) {
	var Component = _ember['default'].Component;
	var escapeExpression = _ember['default'].Handlebars.Utils.escapeExpression;
	var htmlSafe = _ember['default'].String.htmlSafe;
	var computed = _ember['default'].computed;
	var run = _ember['default'].run;
	var $ = _ember['default'].$;
	exports['default'] = Component.extend({
		layout: _paperDataTableTemplatesComponentsPaperDataTableDialogInner['default'],
		tagName: 'md-edit-dialog',
		attributeBindings: ['style'],
		width: null,
		transitionClass: 'ng',
		classNames: ['md-whiteframe-1dp'],
		style: computed('left', 'top', 'width', function () {
			var left = escapeExpression(this.get('left'));
			var top = escapeExpression(this.get('top'));
			var width = escapeExpression(this.get('width'));
			return htmlSafe('left: ' + left + 'px;top: ' + top + 'px; min-width: ' + width + 'px;');
		}),

		positionDialog: function positionDialog() {
			var element = this.get('element') || { clientWidth: 0, clientHeight: 0 };
			var size = { width: element.clientWidth, height: element.clientHeight };
			var cellBounds = $('#' + this.get('parent'))[0].getBoundingClientRect();
			var tableBounds = this._mdTableContainer.getBoundingClientRect();

			if (size.width > tableBounds.right - cellBounds.left) {
				this.set('left', tableBounds.right - size.width);
			} else {
				this.set('left', cellBounds.left);
			}

			if (size.height > tableBounds.bottom - cellBounds.top) {
				this.set('top', tableBounds.bottom - size.height);
			} else {
				this.set('top', cellBounds.top + 1);
			}
			this.set('width', this.get('row') ? tableBounds.width : cellBounds.width);
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			this._mdTableContainer = this.$().closest('md-table-container')[0];
			$(window).on('resize', this.positionDialog.bind(this));
			run.scheduleOnce('afterRender', this, function () {
				this.positionDialog();
				this.$('input').first().focus();
			});
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			$(window).off('resize', this.positionDialog.bind(this));
		}
	});
});