define('ember-cpm/macros/promise', ['exports', 'ember-cpm/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var resolve = Ember.RSVP.resolve;
  exports.default = (0, _utils.resolveKeys)(function (value) {
    if (value == null) {
      return value;
    }
    return resolve(value);
  });
});