define('ember-cpm/macros/concat', ['exports', 'ember-macro-helpers/computed', 'ember-macro-helpers/normalize-array-key'], function (exports, _computed, _normalizeArrayKey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var arrayReduce = function arrayReduce() {
      for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      return args.reduce(function (prev, value) {
        return prev.concat(value || []);
      }, []);
    };

    return _computed.default.apply(undefined, _toConsumableArray(args.map(function (a) {
      return (0, _normalizeArrayKey.default)(a, []);
    })).concat([arrayReduce]));
  };

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }
});