define('paper-data-table/components/paper-data-table-edit-dialog', ['exports', 'ember', 'paper-data-table/templates/components/paper-data-table-edit-dialog'], function (exports, _ember, _paperDataTableTemplatesComponentsPaperDataTableEditDialog) {
	var Component = _ember['default'].Component;
	exports['default'] = Component.extend({
		layout: _paperDataTableTemplatesComponentsPaperDataTableEditDialog['default'],
		tagName: '',
		actions: {
			outsideClicked: function outsideClicked() {
				this.get('onClose')();
			}
		}
	});
});