define('ember-cpm/macros/literal', ['exports', 'ember-macro-helpers/literal'], function (exports, _literal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _literal.default;
    }
  });
});