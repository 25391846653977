define('ember-cpm/macros/fmt', ['exports', 'ember-cpm/utils', 'ember-macro-helpers/computed'], function (exports, _utils, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var formatString = args.pop();

    return _computed.default.apply(undefined, args.concat([function () {
      for (var _len2 = arguments.length, values = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        values[_key2] = arguments[_key2];
      }

      for (var i in values) {
        var value = values[i];
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
      }

      return (0, _utils.fmt)(formatString, values);
    }]));
  };
});