define('paper-data-table/components/paper-data-table-cell', ['exports', 'ember', 'paper-data-table/templates/components/paper-data-table-cell'], function (exports, _ember, _paperDataTableTemplatesComponentsPaperDataTableCell) {
	var Component = _ember['default'].Component;
	var escapeExpression = _ember['default'].Handlebars.Utils.escapeExpression;
	var htmlSafe = _ember['default'].String.htmlSafe;
	var computed = _ember['default'].computed;
	exports['default'] = Component.extend({
		layout: _paperDataTableTemplatesComponentsPaperDataTableCell['default'],
		tagName: 'td',
		classNameBindings: ['numeric:md-numeric', 'edit:md-clickable'],
		attributeBindings: ['style', 'colspan'],
		classNames: ['md-cell'],
		edit: false,
		showEdit: false,
		width: null,
		style: computed('width', function () {
			var width = escapeExpression(this.get('width'));
			if (width) {
				return htmlSafe('width: ' + width + 'px;');
			} else {
				return undefined;
			}
		}),
		click: function click() {
			this.sendAction('onClick');
			this.set('showEdit', true);
		},
		actions: {
			close: function close() {
				this.sendAction('onClose', this);
			},
			toggleEdit: function toggleEdit() {
				this.toggleProperty('showEdit');
			}
		}
	});
});