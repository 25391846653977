define('ember-cpm/index', ['exports', 'ember-cpm/macros/among', 'ember-cpm/macros/all-equal', 'ember-cpm/macros/encode-uri-component', 'ember-cpm/macros/encode-uri', 'ember-cpm/macros/first-present', 'ember-cpm/macros/fmt', 'ember-cpm/macros/html-escape', 'ember-cpm/macros/if-null', 'ember-cpm/macros/promise', 'ember-cpm/macros/safe-string', 'ember-cpm/macros/join', 'ember-cpm/macros/sum', 'ember-cpm/macros/concat', 'ember-cpm/macros/conditional', 'ember-cpm/macros/product', 'ember-cpm/macros/quotient', 'ember-cpm/macros/difference', 'ember-cpm/macros/not', 'ember-cpm/macros/as-float', 'ember-cpm/macros/as-int', 'ember-cpm/macros/computed-promise'], function (exports, _among, _allEqual, _encodeUriComponent, _encodeUri, _firstPresent, _fmt, _htmlEscape, _ifNull, _promise, _safeString, _join, _sum, _concat, _conditional, _product, _quotient, _difference, _not, _asFloat, _asInt, _computedPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Macros = exports.VERSION = undefined;


  var VERSION = '1.3.1';
  var Macros = {
    not: _not.default,
    among: _among.default,
    allEqual: _allEqual.default,
    encodeURIComponent: _encodeUriComponent.default,
    encodeURI: _encodeUri.default,
    firstPresent: _firstPresent.default,
    fmt: _fmt.default,
    htmlEscape: _htmlEscape.default,
    ifNull: _ifNull.default,
    promise: _promise.default,
    safeString: _safeString.default,
    join: _join.default,
    sum: _sum.default,
    difference: _difference.default,
    concat: _concat.default,
    conditional: _conditional.default,
    asFloat: _asFloat.default,
    asInt: _asInt.default,
    quotient: _quotient.default,
    product: _product.default,
    computedPromise: _computedPromise.default
  };

  if (Ember.libraries) {
    Ember.libraries.register('Ember-CPM', VERSION);
  }

  exports.VERSION = VERSION;
  exports.Macros = Macros;
  exports.default = {
    VERSION: VERSION,
    Macros: Macros
  };
});