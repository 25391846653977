define('ember-cpm/macros/mean', ['exports', 'ember-cpm/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _utils.resolveKeysUnsafe)(function () {
    for (var _len = arguments.length, values = Array(_len), _key = 0; _key < _len; _key++) {
      values[_key] = arguments[_key];
    }

    var sum = 0;
    var count = 0;

    values.forEach(function (v) {
      var t = Ember.typeOf(v);
      switch (t) {
        case 'number':
          // Number case
          count += 1;
          sum += v;
          break;
        case 'array':
          // Array case
          sum += v.reduce(function (p, i) {
            return p + i;
          }, 0); // sum of array
          count += v.length;
          break;
        case 'undefined':
        case 'null':
          break;
        default:
          var msg = 'Unsupported value type: ' + t;
          throw new TypeError(msg);
      }
    });
    return count > 0 ? sum / count : 0;
  });
});