define('paper-data-table/components/paper-data-table-column', ['exports', 'ember', 'paper-data-table/templates/components/paper-data-table-column'], function (exports, _ember, _paperDataTableTemplatesComponentsPaperDataTableColumn) {
	var Component = _ember['default'].Component;
	var escapeExpression = _ember['default'].Handlebars.Utils.escapeExpression;
	var htmlSafe = _ember['default'].String.htmlSafe;
	var computed = _ember['default'].computed;
	exports['default'] = Component.extend({
		layout: _paperDataTableTemplatesComponentsPaperDataTableColumn['default'],
		tagName: 'th',
		classNameBindings: ['numeric:md-numeric', 'active:md-active', 'sortProp:md-sort'],
		attributeBindings: ['style'],
		classNames: ['md-column'],
		currentProp: null,
		sortProp: null,
		sortDir: null,

		style: computed('width', function () {
			var width = escapeExpression(this.get('width'));
			if (width) {
				return htmlSafe('width: ' + width + 'px;');
			}
			return undefined;
		}),

		active: computed('sortProp', 'currentProp', function () {
			return this.get('sortProp') && this.get('sortProp') === this.get('currentProp');
		}).readOnly(),

		click: function click() {
			var _getProperties = this.getProperties('sortProp', 'sortDir', 'active');

			var sortProp = _getProperties.sortProp;
			var sortDir = _getProperties.sortDir;
			var active = _getProperties.active;

			if (!sortProp) {
				return;
			}

			var newSortDir = sortDir;
			if (!active) {
				newSortDir = 'asc';
			} else {
				newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
			}

			this.get('sortChanged')(sortProp, newSortDir);
			this.set('sortDir', newSortDir);
		}
	});
});